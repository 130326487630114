import styled from "styled-components";

const ContainerImg = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;

  img {
    width: auto;
    height: auto;
  }
`;

export default ContainerImg;