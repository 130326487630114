import { Link } from "react-router-dom";
import styled from "styled-components";
import { useLocation } from "react-router-dom";
import { useState, useEffect } from "react";

const Sidenav = styled.div`
  height: 100%;
  width: 185px;
  position: fixed;
  z-index: 2;
  top: 0;
  left: 0;
  background-color: #111;
  overflow-x: hidden;
  padding-top: 20px;
  margin-top: 60px;

  @media screen and (max-width: 800px) {
    width: 60%;
  }
`;

const A = styled.a`
  padding: 6px 8px 6px 16px;
  text-decoration: none;
  font-size: 25px;
  color: #818181;
  display: block;
  font-size: 1.2rem;
`;

const URL_PARAMS_TEMA = 2;

const Sidebar = () => {
  const { pathname } = useLocation();
  const [show, setShow] = useState(false);
  const [rota, setRota] = useState("/");

  useEffect(() => {
    if (`${pathname}` === "/") setShow(false);
    else setShow(true);

    setRota(pathname.split("/")[URL_PARAMS_TEMA]);
  }, [pathname]);

  return (
    <>
      {(show && rota === "flexstore" && (
        <Sidenav>
          <A as={Link} to="/">
            Home
          </A>
          <A as={Link} to="/apresentacao/flexstore">
            Apresentação
          </A>
          <A as={Link} to="/instalacao/flexstore">
            Instalação
          </A>
          <A as={Link} to="/paineldotema/flexstore">
            Painel do Tema
          </A>
          <A as={Link} to="/configuracao/flexstore">
            Configuração
          </A>
          <A as={Link} to="/banners/flexstore">
            Banners
          </A>
          <A as={Link} to="/edicaodocodigo/flexstore">
            Edição do Código
          </A>
          <A as={Link} to="/consideracoesfinais/flexstore">
            Considerações Finais
          </A>
        </Sidenav>
      )) ||
        (show && rota === "altstore" && (
          <Sidenav>
            <A as={Link} to="/">
              Home
            </A>
            <A as={Link} to="/apresentacao/altstore">
              Apresentação
            </A>
            <A as={Link} to="/instalacao/altstore">
              Instalação
            </A>
            <A as={Link} to="/paineldotema/altstore">
              Painel do Tema
            </A>
            <A as={Link} to="/configuracao/altstore">
              Configuração
            </A>
            <A as={Link} to="/banners/altstore">
              Banners
            </A>
            <A as={Link} to="/edicaodocodigo/altstore">
              Edição do Código
            </A>
            <A as={Link} to="/consideracoesfinais/altstore">
              Considerações Finais
            </A>
          </Sidenav>
        ))}
    </>
  );
};

export default Sidebar;
