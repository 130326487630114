import { Suspense, lazy } from "react";
import { Switch, Route } from "react-router-dom";

const Home = lazy(() => import("./home"));
const Apresentacao = lazy(() => import("./apresentacao"));
const Instalacao = lazy(() => import("./instalacao"));
const PainelDoTema = lazy(() => import("./painelDoTema"));
const Configuracao = lazy(() => import("./configuracao"));
const Banners = lazy(() => import("./banners"));
const EdicaoDoCodigo = lazy(() => import("./edicaoDoCodigo"));
const ConsideracoesFinais = lazy(() => import("./consideracoesFinais"));

const Error = lazy(() => import("./error404"));

const AppRoutes = () => (
  <Suspense fallback={<></>}>
    <Switch>
      <Route exact path="/" component={() => <Home />} />
      <Route exact path="/apresentacao/:theme" component={() => <Apresentacao />} />
      <Route exact path="/instalacao/:theme" component={() => <Instalacao />} />
      <Route exact path="/paineldotema/:theme" component={() => <PainelDoTema />} />
      <Route exact path="/configuracao/:theme" component={() => <Configuracao />} />
      <Route exact path="/banners/:theme" component={() => <Banners />} />
      <Route exact path="/edicaodocodigo/:theme" component={() => <EdicaoDoCodigo />} />
      <Route exact path="/consideracoesfinais/:theme" component={() => <ConsideracoesFinais />} />

      <Route path="*" component={() => <Error />} />
    </Switch>
  </Suspense>
);

export default AppRoutes;
