import {
  useContext,
  useState,
  useEffect,
  ReactChild,
  ReactChildren,
} from "react";
import { useLocation } from "react-router-dom";
import Switch from "react-switch";
import styled, { ThemeContext } from "styled-components";
import { shade } from "polished";
import Sidebar from "./sidebar";

import AlignJustify from "../atoms/alignJustifySVG";
import Xsvg from "../atoms/xSVG";

import ContainerImg from "../atoms/containerImg";
import logo from "../../assets/img/logo_fundo_branco.png";

const Container = styled.div`
  position: fixed;
  width: 100%;
  top: 0;
  height: 60px;
  background: ${(props) => props.theme.colors.header};
  color: ${(props) => props.theme.colors.text};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 30px;
  z-index: 1;

  svg {
    display: none;
  }
  img {
    height: 50px;
    width: auto;
  }

  @media screen and (max-width: 800px) {
    svg {
      display: block;
    }
  }
`;

const Button = styled.button`
  margin-left: 2px;
  height: 20px;
  width: 20px;
  border: none;
  background: none;
  cursor: pointer;
  position: absolute;

  @media screen and (min-width: 801px) {
    display: none;
  }
`;

interface Props {
  toggleTheme?(): void;
  children?: ReactChild | ReactChildren;
}

const Header = ({ toggleTheme = (): void => {}, children }: Props) => {
  const { colors, title } = useContext(ThemeContext);
  const [show, setShow] = useState(false);
  const { pathname } = useLocation();

  const handler = () => {
    setShow(!show);
  };

  useEffect(() => {
    setShow(false);
  }, [pathname]);

  return (
    <Container>
      {`${pathname}` !== "/" && (!show ? <AlignJustify /> : <Xsvg />)}
      {`${pathname}` !== "/" && <Button onClick={handler}></Button>}
      {show && <Sidebar />}
      <ContainerImg>
        <img src={logo} alt="7 Digital"></img>
      </ContainerImg>
      {/* <h1>7 Digital</h1> */}
      <Switch
        onChange={toggleTheme}
        checked={title === "dark"}
        checkedIcon={false}
        uncheckedIcon={false}
        height={10}
        width={40}
        handleDiameter={20}
        offColor={shade(0.15, colors.border)}
        onColor={colors.border}
      />
    </Container>
  );
};

export default Header;
