import { BrowserRouter as Router } from "react-router-dom";
import { ThemeProvider, DefaultTheme } from "styled-components";
import GlobalStyle from "./styles/GlobalStyle";
import usePeristedState from "./services/usePersistedState";

import Header from "./components/organisms/header";
import Sidebar from "./components/organisms/sidebar";
import SideBody from "./components/atoms/sideBody";
import ShowDisplay from "./components/atoms/showDisplay";

import Routes from "./routes/index";
import light from "./styles/themes/light";
import dark from "./styles/themes/dark";

function App() {
  const [theme, setTheme] = usePeristedState<DefaultTheme>("theme", light);

  const toggleTheme = () => {
    setTheme(theme.title === "light" ? dark : light);
  };

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <Router>
        <Header toggleTheme={toggleTheme} />
        <ShowDisplay>
          <Sidebar />
        </ShowDisplay>
        <SideBody>
          <Routes />
        </SideBody>
      </Router>
    </ThemeProvider>
  );
}

export default App;