import { ReactChild, ReactChildren } from "react";
import styled from "styled-components";

interface AuxProps {
  children: ReactChild | ReactChildren;
}

const Container = styled.div`
  position: relative;
  margin-left: 210px;
  margin-top: 80px;
  margin-right: 18px;

  @media screen and (max-width: 800px) {
    margin: 65px 15px 15px 15px;
  }
`;

const SideBody = ({ children }: AuxProps) => <Container>{children}</Container>;

export default SideBody;
