const dark = {
  title: "dark",

  colors: {
    primary: "#1d3c45",
    background: "#fff1e1",
    header: "#1d3c45",
    border: "#d2601a",
    text: "#d2601a",

    dark: "#000000",
    white: "#ffffff",
  },
};

export default dark;
