import { ReactChild, ReactChildren } from "react";
import styled from "styled-components";

interface AuxProps {
  children: ReactChild | ReactChildren;
}

const Container = styled.div`
  @media screen and (max-width: 800px) {
    display: none;
  }
`;

const ShowDisplay = ({ children }: AuxProps) => <Container>{children}</Container>;

export default ShowDisplay;
