const light = {
  title: "light",

  colors: {
    primary: "#aed6dc",
    background: "#ffffff",
    header: "#00b0ff",
    border: "#ff9a8d",
    text: "#4a536b",

    dark: "#000000",
    white: "#ffffff",
  },
};

export default light;
